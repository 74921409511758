<template>
  <div
    class="frame-nav-page"
    :class="footer ? 'footer' : ''"
    v-if="listePages.length > 1"
  >
    <span
      class="element-clickable page-nav"
      @click="() => definirPageAAfficher(pageAAfficher - 1)"
      >&lt;</span
    >
    <select
      name="page-saisie"
      id="page-saisie"
      v-model="pageAAfficherPrivate"
      @change="() => $emit('update', pageAAfficherPrivate)"
    >
      <option
        v-for="(page, index) in listePages"
        :key="'page-' + index"
        :value="index + 1"
        >{{ page }}</option
      >
      >
    </select>
    <span
      class="element-clickable page-nav"
      @click="() => definirPageAAfficher(pageAAfficher + 1)"
      >&gt;</span
    >
  </div>
</template>

<script>
export default {
  name: "ComposantSelectionPage",
  props: {
    footer: { default: false },
    listePages: { default: [] },
    pageAAfficher: { default: 1 },
    definirPageAAfficher: {
      type: Function,
      default: () => {
        console.error(
          "ComposantSelectionPage : fonction de définition de page manquante"
        );
      },
    },
  },
  model: {
    prop: "pageAAfficher",
    event: "update",
  },
  data: () => {
    return {
      pageAAfficherPrivate: 1,
    };
  },
  watch: {
    pageAAfficher() {
      this.pageAAfficherPrivate = this.pageAAfficher;
    },
  },
  mounted: function() {
    this.pageAAfficherPrivate = this.pageAAfficher;
  },
};
</script>

<style lang="scss">
@import "src/assets/css/components.scss";
.frame-nav-page {
  display: flex;
  &.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
  @media (max-width: $mobile-break-point) {
    order: 3;
    margin-bottom: 10px;
  }
}

.page-nav {
  font-size: 1.4em;
  position: relative;
  top: 3px;
  margin: 0 3px;
  @media (max-width: $mobile-break-point) {
    margin: 0 8px;
    font-size: 2.2em;
  }
}
</style>
